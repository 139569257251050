var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container finishedMaterials formPart" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: {
                          filterable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierIdList,
                          callback: function($$v) {
                            _vm.supplierIdList = $$v
                          },
                          expression: "supplierIdList"
                        }
                      },
                      _vm._l(_vm.supplierNameList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        disabled: this.isLoadingExport
                      },
                      on: { click: _vm.exportHandle }
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo inline_block mr10",
                      attrs: {
                        headers: _vm.myHeaders,
                        action: this.uploadurl,
                        accept: ".xlsx",
                        "before-upload": _vm.beforeupload,
                        data: { applicantName: this.$store.state.realName },
                        "show-file-list": _vm.showfilelist,
                        "on-success": _vm.UploadSuccess
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: this.isLoadingImport
                          },
                          on: { click: _vm.importHandle }
                        },
                        [_vm._v("导入")]
                      )
                    ],
                    1
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "566px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.materialsList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        cloneHandle: _vm.cloneHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _vm.actionsType == "check"
              ? _c(
                  "div",
                  [
                    _c("finishedMaterialsCheck", {
                      attrs: {
                        list: _vm.ruleForm,
                        uploadFileList: _vm.uploadFileList
                      },
                      on: { cancelHandle: _vm.baseCancelHandle }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "base_wrap clearfix finished" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("物料基本信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料编号", prop: "materialCode" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: this.actionsType == "edit" },
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatMeterialClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupMaterialCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.materialCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialCode"
                                }
                              }),
                              this.actionsType == "edit"
                                ? _c("el-button", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "更新"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.popoverHandle(_vm.ruleForm)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "系统编号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.materialId))
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料名称" } },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                model: {
                                  value: _vm.ruleForm.materialName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "materialName", $$v)
                                  },
                                  expression: "ruleForm.materialName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "供应商编号", prop: "supplierId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select_single w200 mr10",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectSupplierclick(
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.supplierId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "supplierId", $$v)
                                    },
                                    expression: "ruleForm.supplierId"
                                  }
                                },
                                _vm._l(_vm.supplierNameList, function(item) {
                                  return _c("el-option", {
                                    key: item.supplierId,
                                    attrs: {
                                      label: item.supplierCode,
                                      value: item.supplierId
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "供应商名称" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis w200",
                                  attrs: { title: _vm.ruleForm.supplierName }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ruleForm.supplierName
                                        ? _vm.ruleForm.supplierName
                                        : ""
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物料分类",
                                prop: "materialClassification"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeGramWeight(
                                        _vm.ruleForm.materialClassification,
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.materialClassification,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "materialClassification",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.materialClassification"
                                  }
                                },
                                _vm._l(_vm.materialClassList, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.index,
                                      attrs: {
                                        value: item.index,
                                        label: item.name
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "颜色", prop: "color" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.color,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "color",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.color"
                                  }
                                },
                                _vm._l(_vm.colortypelist, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.index,
                                      attrs: {
                                        value: item.index,
                                        label: item.name
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品特性",
                                prop: "productSeason"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productSeason,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productSeason",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productSeason"
                                  }
                                },
                                _vm._l(_vm.seasonList, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.index,
                                      attrs: {
                                        value: item.index,
                                        label: item.name
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "采购计量单位",
                                prop: "measurementUnit"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.measurementUnit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "measurementUnit",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.measurementUnit"
                                  }
                                },
                                _vm._l(_vm.unitList, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.index,
                                      attrs: {
                                        value: item.index,
                                        label: item.name
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位重量" } },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { disabled: true, maxlength: "6" },
                                on: {
                                  change: function($event) {
                                    return _vm.InputNumber("unitGram")
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.InputNumber("unitGram")
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.unitGram,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "unitGram", $$v)
                                  },
                                  expression: "ruleForm.unitGram"
                                }
                              }),
                              _vm._v("  克\n            ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料属性" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.materialProperties,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "materialProperties",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.materialProperties"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    key: "",
                                    attrs: { label: "请选择", value: "" }
                                  }),
                                  _vm._l(
                                    _vm.MaterialPropertiestypelist,
                                    function(item) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.index,
                                          attrs: {
                                            value: item.index,
                                            label: item.name
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "系列" } },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { maxlength: "64" },
                                model: {
                                  value: _vm.ruleForm.materialSeries,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialSeries",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialSeries"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上市时间" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w200",
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsStart,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.listingDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "listingDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.listingDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "生命周期" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "w200",
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsEnd,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.lifeCycle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "lifeCycle",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.lifeCycle"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报关品类",
                                prop: "declarationCategory"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w200",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectdeclarationCategory(
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.declarationCategory,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "declarationCategory",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.declarationCategory"
                                  }
                                },
                                _vm._l(
                                  _vm.materialDeclarationCategorylist,
                                  function(item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.index,
                                        attrs: {
                                          value: item.index,
                                          label: item.name
                                        }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "海关编号" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.ruleForm.hsCode))])
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "杂费系数",
                                prop: "miscChargesCoefficient"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { maxlength: "10" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.miscChargesCoefficient = _vm.oninput(
                                      _vm.ruleForm.miscChargesCoefficient
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.miscChargesCoefficient = _vm.oninput(
                                      _vm.ruleForm.miscChargesCoefficient
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.miscChargesCoefficient,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "miscChargesCoefficient",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.miscChargesCoefficient"
                                }
                              }),
                              _vm._v("\n                \n              ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "海关关税系数" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.customsTariffCoefficient)
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "增值税系数",
                                prop: "valueAddedTaxCoefficient"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { maxlength: "10" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.valueAddedTaxCoefficient = _vm.oninput(
                                      _vm.ruleForm.valueAddedTaxCoefficient
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.valueAddedTaxCoefficient = _vm.oninput(
                                      _vm.ruleForm.valueAddedTaxCoefficient
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.valueAddedTaxCoefficient,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "valueAddedTaxCoefficient",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.valueAddedTaxCoefficient"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "last",
                              attrs: { label: "备注", prop: "remarks" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 8, maxRows: 4 },
                                  placeholder: "多行输入",
                                  maxlength: "1000",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.ruleForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "remarks", $$v)
                                  },
                                  expression: "ruleForm.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt20 t_right" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr10",
                              on: { click: _vm.baseCancelHandle }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "preventReClick",
                                  rawName: "v-preventReClick"
                                }
                              ],
                              attrs: {
                                type: "primary",
                                loading: this.loadingcommit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.baseSaveHandle("ruleForm")
                                }
                              }
                            },
                            [_vm._v("保存")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                visible: _vm.dialogpopupVisible
              },
              on: {
                close: _vm.closeDialog,
                "update:visible": function($event) {
                  _vm.dialogpopupVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.materialCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "materialCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "table_list",
                  attrs: { data: _vm.popoverslist, border: "", fit: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "系统名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n                  " +
                                _vm._s(row.systemName) +
                                "\n              "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "物料编号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.materialCode) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单据名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.tableNameCh) +
                                " \n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "记录数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.recordNum) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.cancelpopoverHandle()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.savepopoverHandle()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }