var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix finished" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.materialId ? _vm.ruleForm.materialId : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialName ? _vm.ruleForm.materialName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandleSupplier(
                      _vm.ruleForm.supplierId,
                      this.supplierNameList
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.supplierName ? _vm.ruleForm.supplierName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料分类" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialClassification,
                      this.materialClassList
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "颜色" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(_vm.typeHandle(_vm.ruleForm.color, this.colortypelist))
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品特性" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.productSeason, this.seasonList)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "采购计量单位" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.measurementUnit, this.unitList)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "单位重量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.unitGram ? _vm.ruleForm.unitGram : "")
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "物料属性" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialProperties,
                      this.MaterialPropertiestypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系列" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialSeries
                      ? _vm.ruleForm.materialSeries
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "上市时间" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.listingDate ? _vm.ruleForm.listingDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.lifeCycle ? _vm.ruleForm.lifeCycle : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "报关品类" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.declarationCategory,
                      this.materialDeclarationCategorylist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "海关编号" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.hsCode ? _vm.ruleForm.hsCode : ""))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "杂费系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.miscChargesCoefficient
                      ? _vm.ruleForm.miscChargesCoefficient
                      : ""
                  )
                )
              ]),
              _vm._v("  \n         ")
            ]),
            _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.customsTariffCoefficient
                      ? _vm.ruleForm.customsTariffCoefficient
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "增值税系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.valueAddedTaxCoefficient
                      ? _vm.ruleForm.valueAddedTaxCoefficient
                      : ""
                  )
                )
              ])
            ]),
            _c(
              "el-form-item",
              {
                staticClass: "last",
                attrs: { label: "备注", prop: "remarks" }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.ruleForm.remarks ? _vm.ruleForm.remarks : "")
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt20 t_right" },
          [
            _c(
              "el-button",
              { staticClass: "mr10", on: { click: _vm.cancelHandle } },
              [_vm._v("取消")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }