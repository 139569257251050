var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials nav_tab_wrap" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "nav_wrap",
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: { label: "面料物料主数据维护", name: "getfabricMaterials" }
            },
            [_vm.isFabric ? _c("fabricMaterials") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "成品物料主数据维护",
                name: "getfinishedMaterials"
              }
            },
            [_vm.isFinished ? _c("finishedMaterials") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "组合物料主数据维护",
                name: "getcompositeMaterials"
              }
            },
            [_vm.isComposite ? _c("compositeMaterials") : _vm._e()],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }