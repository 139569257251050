var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix finished" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.materialId ? _vm.ruleForm.materialId : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialName ? _vm.ruleForm.materialName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生产商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.manufacturerName
                      ? _vm.ruleForm.manufacturerName
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料分类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialClassification,
                      this.MaterialClasstypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商编号" } }, [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.typeHandleSupplier(
                        _vm.ruleForm.supplierId,
                        this.supplierNameList
                      )
                    ) +
                    "\n          "
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料属性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialProperties,
                      this.MaterialPropertiestypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.supplierName ? _vm.ruleForm.supplierName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品特性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productSeason,
                      this.seasontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "总页数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.pageCount ? _vm.ruleForm.pageCount : "")
                )
              ]),
              _vm._v("  页\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "销售季" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.saleSeason,
                      this.SaleSeasoutypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "品牌" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.brand,
                      this.MaterialCodetypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "计量单位" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.calculationUnit,
                      this.SaleUnittypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "上市时间" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.validDate ? _vm.ruleForm.validDate : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "单位重量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.unitGram ? _vm.ruleForm.unitGram : "450")
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "报关品类" } }, [
              _c("span", { staticClass: "w300" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.declarationCategory,
                      this.materialDeclarationCategorylist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.invalidDate ? _vm.ruleForm.invalidDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "海关编号" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.hsCode ? _vm.ruleForm.hsCode : ""))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "杂费系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.miscChargesCoefficient
                      ? _vm.ruleForm.miscChargesCoefficient
                      : ""
                  )
                )
              ]),
              _vm._v("  \n        ")
            ]),
            _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.customsTariffCoefficient
                      ? _vm.ruleForm.customsTariffCoefficient
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "增值税系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.valueAddedTaxCoefficient
                      ? _vm.ruleForm.valueAddedTaxCoefficient
                      : ""
                  )
                )
              ])
            ]),
            _c(
              "el-form-item",
              { staticClass: "whole", attrs: { label: "备注" } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.ruleForm.remarks ? _vm.ruleForm.remarks : "")
                  )
                ])
              ]
            ),
            _c("hr", { staticStyle: { border: "0.5px solid #DCDFE6" } }),
            _c("div", { staticClass: "childtit" }, [
              _c("h2", { staticClass: "title" }, [_vm._v("组合物料组成信息")])
            ]),
            _c(
              "div",
              { staticClass: "addcontent_content" },
              _vm._l(this.ruleForm.combinedMaterialMessagesList, function(
                item,
                index
              ) {
                return _c("ul", { key: index, staticClass: "content_form" }, [
                  _c("li", { staticClass: "w20p" }, [
                    _c("div", { staticClass: "form_item" }, [
                      _c("span", [
                        _vm._v("组成物料编号："),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.combinedMaterialCode
                                ? item.combinedMaterialCode
                                : ""
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "w8p" }, [
                    _c("div", { staticClass: "form_item" }, [
                      _c("span", [
                        _vm._v("页码："),
                        _c("span", [
                          _vm._v(_vm._s(item.pageNumber ? item.pageNumber : ""))
                        ])
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "w9p" }, [
                    _c("div", { staticClass: "form_item" }, [
                      _c("span", [
                        _vm._v("位置："),
                        _c("span", [
                          _vm._v(_vm._s(item.position ? item.position : ""))
                        ])
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "w7p" }, [
                    _c("div", { staticClass: "form_item" }, [
                      _c("span", [_vm._v("拍照款：")]),
                      _c("span", [
                        _vm._v(_vm._s(item.photoStyle == true ? "是" : "否"))
                      ])
                    ])
                  ]),
                  _c("li", { staticClass: "w12p" }, [
                    _c("div", { staticClass: "form_item" }, [
                      _c("span", [_vm._v("备注：")]),
                      _c("span", [
                        _vm._v(_vm._s(item.remarks ? item.remarks : ""))
                      ])
                    ])
                  ])
                ])
              }),
              0
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }