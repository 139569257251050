var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "base_wrap clearfix finished" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-form-inline",
            attrs: {
              inline: true,
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "200px"
            }
          },
          [
            _c("el-form-item", { attrs: { label: "物料编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialCode ? _vm.ruleForm.materialCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系统编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.materialId ? _vm.ruleForm.materialId : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "开发号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.developCode ? _vm.ruleForm.developCode : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialName ? _vm.ruleForm.materialName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商编号" } }, [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.typeHandleSupplier(
                        _vm.ruleForm.supplierId,
                        this.supplierNameList
                      )
                    ) +
                    "\n          "
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "供应商名称" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.supplierName ? _vm.ruleForm.supplierName : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "技术规格描述" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.combination ? _vm.ruleForm.combination : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "系列" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialSeries
                      ? _vm.ruleForm.materialSeries
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料成分" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.materialComposition
                      ? _vm.ruleForm.materialComposition
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "工厂季" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.factorySeason,
                      this.factorySeasontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "品类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.category,
                      this.ProductCategorytypelist,
                      "category"
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "产品特性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.productSeason,
                      this.seasontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "颜色" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.typeHandle(_vm.ruleForm.color, this.colortypelist))
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "采购计量单位" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.purchaseMeasurementUnit,
                      this.SaleUnittypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "花型" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(_vm.ruleForm.pattern, this.patterntypelist)
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "延米克重" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.weightPerMeterShow
                      ? _vm.ruleForm.weightPerMeterShow
                      : ""
                  )
                )
              ]),
              _vm._v("  克\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "产地" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.placeOfMaterialion,
                      this.placeProductiontypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "幅宽" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.widthShow ? _vm.ruleForm.widthShow : "")
                )
              ]),
              _vm._v("  \n        ")
            ]),
            _c("el-form-item", { attrs: { label: "物料类型" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialType,
                      this.Materialtypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "纱支" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.yarnCount ? _vm.ruleForm.yarnCount : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "物料属性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.materialProperties,
                      this.MaterialPropertiestypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "最小起订量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.minOrder ? _vm.ruleForm.minOrder : "")
                )
              ]),
              _vm._v("  米\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "新首最小起订量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.newMinOrder ? _vm.ruleForm.newMinOrder : ""
                  )
                )
              ]),
              _vm._v("  米\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "零剪物料属性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.retailMaterialProperties,
                      this.materialPropertiesTypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "经济批量" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.economicBatch ? _vm.ruleForm.economicBatch : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "职业装物料属性" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.suitsMaterialProperties,
                      this.suitsMaterialPropertiesTypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "规格" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.specifications
                      ? _vm.ruleForm.specifications
                      : ""
                  )
                )
              ]),
              _vm._v("  米/包\n        ")
            ]),
            _c("el-form-item", { attrs: { label: "上市日期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.appearDate ? _vm.ruleForm.appearDate : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "批次管理" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.isBatchManagement == true ? "是" : "否")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "生命周期" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.disappearDate ? _vm.ruleForm.disappearDate : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "EV产品" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.belongToEv == true ? "是" : "否"))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "报关品类" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.declarationCategory,
                      this.materialDeclarationCategorylist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "快货" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.mobileGoods == true ? "是" : "否"))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "海关编号" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.hsCode ? _vm.ruleForm.hsCode : ""))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "是否可供" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeHandle(
                      _vm.ruleForm.whetherSupply,
                      this.materialSupplyTypelist
                    )
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.customsTariffCoefficient
                      ? _vm.ruleForm.customsTariffCoefficient
                      : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "替代品" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.ruleForm.substitute ? _vm.ruleForm.substitute : "")
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "增值税系数" } }, [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.valueAddedTaxCoefficient))
              ])
            ]),
            _c("el-form-item", { attrs: { label: "坯布编号" } }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.rawFabricId ? _vm.ruleForm.rawFabricId : ""
                  )
                )
              ])
            ]),
            _c("el-form-item", { attrs: { label: "杂费系数" } }, [
              _c("span", [_vm._v(_vm._s(_vm.ruleForm.miscChargesCoefficient))]),
              _vm._v("  \n        ")
            ]),
            _c(
              "el-form-item",
              { staticClass: "whole", attrs: { label: "备注" } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.ruleForm.remarks ? _vm.ruleForm.remarks : "")
                  )
                ])
              ]
            ),
            _c("hr", { staticStyle: { border: "0.5px solid #DCDFE6" } }),
            _c("h2", { staticClass: "title" }, [
              _vm._v("交期信息（交期时间单位：天）")
            ]),
            _c(
              "el-form-item",
              { staticClass: "message", attrs: { label: "工厂生产交期" } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.materialDeliveryDate.factoryDeliveryDate
                        ? _vm.ruleForm.materialDeliveryDate.factoryDeliveryDate
                        : ""
                    )
                  )
                ])
              ]
            ),
            _c(
              "el-form-item",
              {
                staticClass: "message",
                attrs: { label: "发货（空运+清关+入库操作）交期" }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.materialDeliveryDate.shippmentDate
                        ? _vm.ruleForm.materialDeliveryDate.shippmentDate
                        : ""
                    )
                  )
                ])
              ]
            ),
            _c(
              "el-form-item",
              { staticClass: "message", attrs: { label: "预付款交期" } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.materialDeliveryDate.advancePaymentDate
                        ? _vm.ruleForm.materialDeliveryDate.advancePaymentDate
                        : ""
                    )
                  )
                ])
              ]
            ),
            _c(
              "el-form-item",
              { staticClass: "message", attrs: { label: "新首工厂生产交期" } },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.materialDeliveryDate.firstFactoryDeliveryDate
                        ? _vm.ruleForm.materialDeliveryDate
                            .firstFactoryDeliveryDate
                        : ""
                    )
                  )
                ])
              ]
            ),
            _c(
              "el-form-item",
              {
                staticClass: "message",
                attrs: { label: "新首发货（空运+清关+入库操作）交期" }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.ruleForm.materialDeliveryDate.firstShippmentDate
                        ? _vm.ruleForm.materialDeliveryDate.firstShippmentDate
                        : ""
                    )
                  )
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt20 fr" },
      [
        _c(
          "el-button",
          { staticClass: "mr10", on: { click: _vm.cancelHandle } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }