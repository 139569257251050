var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaterials" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: {
                          filterable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierIdList,
                          callback: function($$v) {
                            _vm.supplierIdList = $$v
                          },
                          expression: "supplierIdList"
                        }
                      },
                      _vm._l(_vm.supplierNameList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        disabled: this.isLoadingExport
                      },
                      on: { click: _vm.exportHandle }
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo inline_block mr10",
                      attrs: {
                        headers: _vm.myHeaders,
                        action: this.uploadurl,
                        accept: ".xlsx",
                        "before-upload": _vm.beforeupload,
                        "show-file-list": _vm.showfilelist,
                        data: {
                          applicantName: this.$store.state.realName,
                          isOrderGoods: "1"
                        },
                        "on-success": _vm.UploadSuccess
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: this.isLoadingImport
                          },
                          on: { click: _vm.importHandle }
                        },
                        [_vm._v("导入")]
                      )
                    ],
                    1
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.fabricList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        cloneHandle: _vm.cloneHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _vm.actionsType == "check"
              ? _c(
                  "div",
                  [
                    _c("fabricMaterialsCheck", {
                      attrs: {
                        list: _vm.ruleForm,
                        uploadFileList: _vm.uploadFileList
                      },
                      on: { cancelHandle: _vm.resetForm }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "base_wrap clearfix finished" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("物料基本信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料编号", prop: "materialCode" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: this.actionsType == "edit" },
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatMeterialClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupMaterialCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.materialCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialCode"
                                }
                              }),
                              this.actionsType == "edit"
                                ? _c("el-button", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "更新"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.popoverHandle(_vm.ruleForm)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "系统编号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.materialId))
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开发号", prop: "developCode" } },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatDevelopCodeClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupDevelopCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.developCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "developCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.developCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料名称" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.materialName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "供应商编号", prop: "supplierId" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select_single w200 mr10",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectSupplierclick(
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.supplierId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "supplierId", $$v)
                                    },
                                    expression: "ruleForm.supplierId"
                                  }
                                },
                                _vm._l(_vm.supplierNameList, function(item) {
                                  return _c("el-option", {
                                    key: item.supplierId,
                                    attrs: {
                                      label: item.supplierCode,
                                      value: item.supplierId
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "供应商名称",
                                prop: "supplierName"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis w200",
                                  attrs: { title: _vm.ruleForm.supplierName }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ruleForm.supplierName
                                        ? _vm.ruleForm.supplierName
                                        : ""
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "技术规格描述" } },
                            [
                              _c("el-input", {
                                attrs: { title: _vm.ruleForm.combination },
                                model: {
                                  value: _vm.ruleForm.combination,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "combination",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.combination"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "系列" } },
                            [
                              _c("el-input", {
                                attrs: { title: _vm.ruleForm.materialSeries },
                                model: {
                                  value: _vm.ruleForm.materialSeries,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialSeries",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialSeries"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料成分" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.materialComposition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialComposition",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialComposition"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "工厂季" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.factorySeason,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "factorySeason",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.factorySeason"
                                  }
                                },
                                _vm._l(this.factorySeasontypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "品类" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.category,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "category",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.category"
                                  }
                                },
                                _vm._l(this.ProductCategorytypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品特性" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productSeason,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productSeason",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productSeason"
                                  }
                                },
                                _vm._l(this.seasontypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "颜色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.color,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "color",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.color"
                                  }
                                },
                                _vm._l(this.colortypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "采购计量单位",
                                prop: "purchaseMeasurementUnit"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.selectUnitclick },
                                  model: {
                                    value: _vm.ruleForm.purchaseMeasurementUnit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "purchaseMeasurementUnit",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.purchaseMeasurementUnit"
                                  }
                                },
                                _vm._l(this.SaleUnittypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "花型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.pattern,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "pattern",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.pattern"
                                  }
                                },
                                _vm._l(this.patterntypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "延米克重" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterOne,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterOne",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterOne"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterTwo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterTwo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterTwo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v("\n              克\n            "),
                              _c("span", { staticClass: "red note" }, [
                                _vm._v(
                                  "注:区间值填写两个值,固定值任意填写一个文本框即可"
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产地",
                                prop: "placeOfMaterialion"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择产地" },
                                  model: {
                                    value: _vm.ruleForm.placeOfMaterialion,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "placeOfMaterialion",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.placeOfMaterialion"
                                  }
                                },
                                _vm._l(this.placeProductiontypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "幅宽" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthStart,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthStart",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthStart"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthEnd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthEnd",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料类型", prop: "materialType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.materialTypeChange },
                                  model: {
                                    value: _vm.ruleForm.materialType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "materialType",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.materialType"
                                  }
                                },
                                _vm._l(this.Materialtypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "纱支", prop: "yarnCount" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.yarnCount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "yarnCount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.yarnCount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料属性" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.materialProperties,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "materialProperties",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.materialProperties"
                                  }
                                },
                                _vm._l(
                                  this.MaterialPropertiestypelist,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        label: item.name,
                                        value: item.index
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "最小起订量" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "6" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.minOrder = _vm.oninput(
                                      _vm.ruleForm.minOrder
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.minOrder = _vm.oninput(
                                      _vm.ruleForm.minOrder
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.minOrder,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "minOrder",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.minOrder"
                                }
                              }),
                              _vm._v("  米\n          ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "新首最小起订量" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "6" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.newMinOrder = _vm.oninput(
                                      _vm.ruleForm.newMinOrder
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.newMinOrder = _vm.oninput(
                                      _vm.ruleForm.newMinOrder
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.newMinOrder,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "newMinOrder",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.newMinOrder"
                                }
                              }),
                              _vm._v("  米\n          ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "零剪物料属性" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value:
                                      _vm.ruleForm.retailMaterialProperties,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "retailMaterialProperties",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.retailMaterialProperties"
                                  }
                                },
                                _vm._l(
                                  this.materialPropertiesTypelist,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        label: item.name,
                                        value: item.index
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "经济批量",
                                prop: "economicBatch"
                              }
                            },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.economicBatch = _vm.oninput(
                                      _vm.ruleForm.economicBatch
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.economicBatch = _vm.oninput(
                                      _vm.ruleForm.economicBatch
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.economicBatch,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "economicBatch",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.economicBatch"
                                }
                              }),
                              _vm._v("  米\n          ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "职业装物料属性" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.suitsMaterialProperties,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "suitsMaterialProperties",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.suitsMaterialProperties"
                                  }
                                },
                                _vm._l(
                                  this.suitsMaterialPropertiesTypelist,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        label: item.name,
                                        value: item.index
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格" } },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.InputNumber("specifications")
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.InputNumber("specifications")
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.specifications,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "specifications",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.specifications"
                                }
                              }),
                              _vm._v("  米/包\n          ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上市日期" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsStart,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.appearDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "appearDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.appearDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "批次管理",
                                prop: "isBatchManagement"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.isBatchManagement,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "isBatchManagement",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.isBatchManagement"
                                  }
                                },
                                _vm._l(this.ifStylelist, function(i) {
                                  return _c("el-option", {
                                    key: i.ifbatch,
                                    attrs: { value: i.ifbatch, label: i.name }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "生命周期" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsEnd,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.disappearDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "disappearDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.disappearDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "EV产品", prop: "belongToEv" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.belongToEv,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "belongToEv",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.belongToEv"
                                  }
                                },
                                _vm._l(this.ifStylelist, function(i) {
                                  return _c("el-option", {
                                    key: i.ifbatch,
                                    attrs: { value: i.ifbatch, label: i.name }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报关品类",
                                prop: "declarationCategory"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function($event) {
                                      return _vm.selectdeclarationCategory(
                                        _vm.ruleForm
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.ruleForm.declarationCategory,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "declarationCategory",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.declarationCategory"
                                  }
                                },
                                _vm._l(
                                  this.materialDeclarationCategorylist,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        label: item.name,
                                        value: item.index
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "快货", prop: "mobileGoods" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.mobileGoods,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "mobileGoods",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.mobileGoods"
                                  }
                                },
                                _vm._l(this.ifStylelist, function(i) {
                                  return _c("el-option", {
                                    key: i.ifbatch,
                                    attrs: { value: i.ifbatch, label: i.name }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "海关编号", prop: "hsCode" } },
                            [_c("span", [_vm._v(_vm._s(_vm.ruleForm.hsCode))])]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否可供" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.whetherSupply,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "whetherSupply",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.whetherSupply"
                                  }
                                },
                                _vm._l(this.materialSupplyTypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      label: item.name,
                                      value: item.index
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "海关关税系数" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.customsTariffCoefficient)
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "替代品", prop: "substitute" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.substitute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "substitute",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.substitute"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "增值税系数",
                                prop: "valueAddedTaxCoefficient"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "10" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.valueAddedTaxCoefficient = _vm.oninput(
                                      _vm.ruleForm.valueAddedTaxCoefficient
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.valueAddedTaxCoefficient = _vm.oninput(
                                      _vm.ruleForm.valueAddedTaxCoefficient
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.valueAddedTaxCoefficient,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "valueAddedTaxCoefficient",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.valueAddedTaxCoefficient"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "坯布编号", prop: "rawFabricId" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.rawFabricId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "rawFabricId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.rawFabricId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "杂费系数",
                                prop: "miscChargesCoefficient"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "10" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.miscChargesCoefficient = _vm.oninput(
                                      _vm.ruleForm.miscChargesCoefficient
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.miscChargesCoefficient = _vm.oninput(
                                      _vm.ruleForm.miscChargesCoefficient
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.miscChargesCoefficient,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "miscChargesCoefficient",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.miscChargesCoefficient"
                                }
                              }),
                              _vm._v("  \n            ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "whole",
                              attrs: { label: "备注", prop: "remarks" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "remarks",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.remarks"
                                }
                              })
                            ],
                            1
                          ),
                          _c("hr", {
                            staticStyle: { border: "0.5px solid #DCDFE6" }
                          }),
                          _c("h2", { staticClass: "title" }, [
                            _vm._v("交期信息（交期时间单位：天）")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "message",
                              attrs: { label: "工厂生产交期" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.ruleForm.materialDeliveryDate
                                      .factoryDeliveryDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.materialDeliveryDate,
                                      "factoryDeliveryDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.materialDeliveryDate.factoryDeliveryDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "message",
                              attrs: { label: "发货（空运+清关+入库操作）交期" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.ruleForm.materialDeliveryDate
                                      .shippmentDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.materialDeliveryDate,
                                      "shippmentDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.materialDeliveryDate.shippmentDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "message",
                              attrs: { label: "预付款交期" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.ruleForm.materialDeliveryDate
                                      .advancePaymentDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.materialDeliveryDate,
                                      "advancePaymentDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.materialDeliveryDate.advancePaymentDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "message",
                              attrs: { label: "新首工厂生产交期" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.ruleForm.materialDeliveryDate
                                      .firstFactoryDeliveryDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.materialDeliveryDate,
                                      "firstFactoryDeliveryDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.materialDeliveryDate.firstFactoryDeliveryDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "message",
                              attrs: {
                                label: "新首发货（空运+清关+入库操作）交期"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.ruleForm.materialDeliveryDate
                                      .firstShippmentDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.materialDeliveryDate,
                                      "firstShippmentDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.materialDeliveryDate.firstShippmentDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          on: {
                            click: function($event) {
                              return _vm.resetForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            loading: this.loadingcommit
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ])
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                visible: _vm.dialogpopupVisible
              },
              on: {
                close: _vm.closeDialog,
                "update:visible": function($event) {
                  _vm.dialogpopupVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.materialCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "materialCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "table_list",
                  attrs: { data: _vm.popoverslist, border: "", fit: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "系统名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.systemName) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "物料编号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.materialCode) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单据名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.tableNameCh) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "记录数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.recordNum) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.cancelpopoverHandle()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.savepopoverHandle()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条面料物料主数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary", loading: _vm.removeloading },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }